
import { defineComponent, toRefs, ref, computed, watch } from "vue";
import { Form, } from "vee-validate";
import InputText from "@/components/forms/InputText.vue" 
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import { FormTypes } from "@/core/enums/form-types"
import * as Validations from "yup";
import moduleForm from '@/store/modules/cashiers/modules/new-cashiers'
import NewCashier from "@/models/cashiers/NewCashier";

export default defineComponent({
    components:{
        Form
        , InputText
        , SelectFilterRemote
    }
    , props:{
        formType: {//indica como se comportara el formulario
            type: Number
            , default: FormTypes.Create
        }
    },
    emits: ['close-content']
    , setup(props, { emit }){
        const { formType } = toRefs(props);
        const form = ref();
        const validationSchema =  Validations.object().shape({
            name: Validations.string().default('').required()
            , code: Validations.string().default('').required()
            , userId: Validations.string().required()
        });
        
        const save = (form: NewCashier, {resetForm}) => {
             //call action
            if(formType.value == FormTypes.Create){
                moduleForm.SAVE_FORM(form).then( (isSuccess) => {
                    if(isSuccess){
                        emit("close-content")
                        resetForm();
                    }
                });
            } else {//edita si no es create
                moduleForm.UPDATE_FORM(form).then(  (isSuccess) => {
                    if(isSuccess){
                        emit("close-content")
                        resetForm();
                    }
                });
            }
        }
        const searchUsers = (filterName: string): void => { 
            //
            moduleForm.getComboUser(filterName);
        }

        const loading = computed(() => moduleForm.loading);
        const userOptions = computed(() => moduleForm.selects.userOptions);

        watch(() => moduleForm.getValuesForm, (currentValue: NewCashier) => {
            if(currentValue && currentValue.name != ''){
                form.value.setValues({
                    ...(currentValue as NewCashier)
                });
            } else {
                form.value.resetForm();
            }

        })


        return {
            FormTypes
            , validationSchema
            , form
            , loading
            , save
            , searchUsers
            , userOptions
        }
    }
})
